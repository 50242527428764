import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import pic6 from "../assets/img/mission-christmas/mission-christmas6.png";
import pic5 from "../assets/img/mission-christmas/mission-christmas5.png";
import pic4 from "../assets/img/mission-christmas/mission-christmas4.png";
import pic1 from "../assets/img/mission-christmas/mission-christmas2.png";
import pic2 from "../assets/img/mission-christmas/mission-christmas1.png";
import pic3 from "../assets/img/mission-christmas/mission-christmas3.png";

import { Link } from "react-router-dom";
const Quenching = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const data = [pic1, pic3, pic2, pic4, pic5, pic6];
  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <div className="container  pb-md-5">
        <div className="container Success mb-5">
          <h2>Mission in Toba Tek Singh Village</h2>
          <p
            style={{
              
              fontSize: "22px",
              letterSpacing: "2px",
            }}
          >
            Toba Tek Singh village near the surroundings of Lahore has been
            dealing with a lot of poverty and not having enough necessities like
            groceries. But there's a bright spot in all of this – the Sam
            Sahotra Foundation (SSF), a team that wants to make life better for
            struggling people. SSF is a non-profit organization that has set
            forth a mission that encompasses various critical areas, such as
            education, health, poverty alleviation through skills enhancement.
          </p>
          <div className="d-flex">
            <Link to="/give/samsahotra">
              <button
                className="paypal_donate mt-2"
                style={{ background: "#4D7C8A" }}
              >
                Give Now
              </button>
            </Link>
            <Link
              to="/mission/christmas"
              onClick={scrollTop}
              style={{ textDecoration: "none" }}
            >
              <button className="paypal_donate mt-2">Read More </button>
            </Link>
          </div>
        </div>
        <Container className=" pt-5 mt-4 mb-4 ">
          <Row className=" detail-content-box ">
            {data?.map((photo, i) => {
              return (
                <>
                  <Col md={4} className="">
                    <div>
                      <div>
                        <img
                          src={photo}
                          className="card-img-top mt-3"
                          height={260}
                          alt="card-img"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Quenching;
